<template>
    <section class="cotizaciones-vigentes container-fluid overflow-auto custom-scroll my-2">
        <tabla-general :usarServidor="true" :usarPaginacion="true" :servidorData="cotizacionesOps" :mostrarBuscador="false" @paginar="listarCotizacionesVigentes" >
            <el-table-column label="Cotización" prop="cotizacion" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos cr-pointer" @click="verCotizacion(scope.row)">
                        {{ scope.row.referencia }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de solicitud" prop="fechaSolicitud" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.fecha_cotizacion | helper-fecha('DD MMM YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Materiales a cotizar" prop="cantMateriales" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.materiales_cotizar }}
                    </p>
                </template>
            </el-table-column>
            <!-- <el-table-column label="Estado" prop="estado" align="center">
                <template slot-scope="scope">
                    <div class="d-middle-center">
                        <i class="f-18" :class="scope.row.estado == 'Habilitado' ? 'icon-file-document-edit-outline text-naranja' : 'icon-progress-pencil'" />
                        <p class="f-12 text-muted2">
                            {{ scope.row.estado  }}
                        </p>
                    </div>
                </template>
            </el-table-column> -->
        </tabla-general>
        <!-- <div class="d-middle-center">
            <Pagination :pagination="cotizacionesOps" layout="prev, pager, next" @paginate="listarCotizacionesVigentes" />
        </div> -->
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data(){
        return{
            buscarListado:'',
            options: [
                {
                    value: 17,
                    label: 'Borrador'
                },
                {
                    value: 18,
                    label: 'Habilitado'
                }
            ],
            cantFiltros: 0,
            valueSelect: '',
            estadoFilter: '',
            pickerFecha: '',
        }
    },
    computed:{
        ...mapGetters({
            cotizacionesOps: 'oportunidades/cotizaciones/cotizaciones/cotizacionesOps',
        }),
        pagination: {
            get() {
                // return this.$store.getters['oportunidades/ver/cotizaciones/paginationDetail']
                return this.$store.getters['oportunidades/cotizaciones/cotizaciones/pagination']
            },
            set(val){
                this.$store.commit('oportunidades/cotizaciones/cotizaciones/pagination', val)
            }
        }

    },
    async created(){
      await this.listarCotizacionesVigentes()
    },

    methods:{
        ...mapMutations({
            set_filter: 'cotizacion/cotizacionesVigentes/set_filter',
        }),
        ...mapActions({
            Action_get_cotizaciones_oportunidad: 'oportunidades/cotizaciones/cotizaciones/Action_get_cotizaciones_oportunidad',
        }),
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async limpiarFiltro(){
            this.cantFiltros = 0
            this.Action_clear_filter()
            await this.listarCotizacionesVigentes()
        },
        async listarCotizacionesVigentes(page = 1){
            await this.Action_get_cotizaciones_oportunidad({idOportunidad:this.$route.params.id, page})
        },
        verCotizacion(item){
            this.$router.push({
                name: 'oportunidades.ver.cotizaciones.ver',
                params: {
                    id_cotizacion: item.id,
                    name_cotizacion: item.referencia,
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.cotizaciones-vigentes{
    height: calc(100vh - 245px);
}
</style>